import React from "react";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardForm from "./CardForm";

//const stripePromise = loadStripe("pk_test_51JiPKUCCM9LkaQosvdDO7lej0dZwx1PisoFsjyG6WWhjjn5SLqNGkL4rDCUVQ2tfrASsFQYDKRxYcwgzVqhPfcHl00mISUMaLS");
const stripePromise = loadStripe("pk_live_51JiPKUCCM9LkaQosHZcZ4Eo5vb7vzrKYwj0FJBaggVZ0KePXVv8UGNOr1fXKDic6wxCHHQmPhQGP8LEnWhlhwv5Z00PmfocVzO");

export default function Checkoutinfo () {
  return (
    <div >
      <Elements stripe={stripePromise}>
            <div className="Demo">
              <CardForm/>
            </div>
      </Elements>
      </div>
  );
};

