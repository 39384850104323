import React, { useState, useEffect } from "react";
import { getCookie } from "../Utils/Cookies";
import { getEmail } from "../Utils/isLoggedIn";
import Checkbox from "react-custom-checkbox";
import Modal from "react-bootstrap/Modal";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import axios from "axios";
import * as Icon from "react-icons/fi";
import { Fetcher } from "../Utils/AxiosWrapper";
export default function CardForm() {
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [orderSuccessful, setOrderSuccessful] = useState(false);
  const [shipping, setShipping] = useState();
  const [shippingCost, setShippingCost] = useState();
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [addressInfo, setAddressInfo] = useState({});
  const [clientSecret, setClientSecret] = useState('');
  const [tax, setTax] = useState("");
  const [items, setItems] = useState([]);
  useEffect(() => {
      let cart = getCookie("cart");
      setItems(JSON.parse("["+cart+"]"));
      let shipping = getCookie("shipping");
      setShipping(JSON.parse(shipping));
  
    }, []);
    const calcTotal = function() {
        let total = 0;
        items.forEach((item) => {
          total = total + (parseInt(item.price) * parseInt(item.qty));
        })
        return total;
    }
  const stripe = useStripe();
  const elements = useElements();
  useEffect(() => {
    if (succeeded)
    {
      let email = getEmail();
      let token = getCookie("token");
      let shippingItems = [];
      items.forEach((item) => {
        shippingItems.push({
          Id: item.id,
          External_Id: item.exid,
          Variant_Id: item.variant_id,
          Sync_variant_id: item.sid,
          Name: item.title.replace("Men's","Men’s",),
          Quantity: item.qty,
          Retail_Price: calcTotal().toFixed(2),
          Product: {Variant_id: item.product.variant_id,
            Product_id: item.product.product_id,
            Image: item.product.image,
            Name: item.product.name,
          }
        })
      })
      Fetcher("POST", "/api/Orders", {
        Email: email, 
        Token: token, 
        Address: {Line1: shipping.line1, StateCode: shipping.state, State: shipping.stateName, City: shipping.city, Zip: shipping.zip},
        Items: shippingItems
      })
      .then(({data}) => {
        console.log("post succeeded");
        setOrderSuccessful(!orderSuccessful);
      })
      .catch((err) => {
        console.log("post failed");
        console.log(err);
        setError("Unable to process your order at this time.");
      });
    }
},[succeeded]);
  useEffect(() => {
    if (shipping && items)
    {
      let shippingItems = [];
      items.forEach((item) => {
        shippingItems.push({Variant_id: item.variant_id,
          Quantity: item.qty})
      })
      Fetcher("POST", "/api/Taxes", {Country: "US", State: shipping.state, City: shipping.city, Zip: shipping.zip})
      .then(({data}) => {
        setTax(data.result.rate);
      })
      .catch((err) => {
      });
      Fetcher("POST", "/api/Shipping", {
        Country: "US",
        State: shipping.state,
        City: shipping.city,
        Zip: shipping.zip,
        Items: shippingItems
        })
      .then(({data}) => {
        console.log(data.result);
        setShippingCost(data.result);
      })
      .catch((err) => {
      });
    }
  }, [shipping, items]);
  const cardStyle = {
    style: {
      base: {
        color: "black",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "18px",
        "::placeholder": {
          color: "gray"
        }
      },
      invalid: {
        color: "black",
        iconColor: "black"
      }
    }
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);
    try{
      const {data} = await Fetcher("GET", "/api/PaymentIntentApi?amount="+(((Number(shippingCost[0].rate)+(Number(tax) * calcTotal())+calcTotal()).toFixed(2))*100)+"&customer=clinton");
      const payload = await stripe.confirmCardPayment(data, {
        payment_method: {
          card: elements.getElement(CardElement)
        }
      });
      if (payload.error) {
        setError(`Payment failed ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
      }
    }
    catch(err) {
      console.log(err);
    }
  };
  return (
    <div style={{minHeight: `80vh`}}>
    <div className="pageHeight" style={{alignItems: `center`, display: `flex`, justifyContent: `center`}}>
      <div className="mobileShipping" style={{color: `black`, justifyContent: `space-between`, width: `90vw`, maxWidth: `600px`, marginBottom: `60px`}}>
        <div>
    <h1 style={{fontSize: `20px`, color: `black`, marginBottom: `30px`}}>Shipping Information</h1>
    <p style={{fontSize: `20px`, color: `black`, marginBottom: `10px`}}>Shipping Address</p>
    {(shipping && shippingCost) && <><p style={{margin: `0px`}}>{shipping.line1}</p>
    <p style={{margin: `0px`}}>{shipping.line2}</p>
    <p>{shipping.city}, {shipping.state} {shipping.zip}</p>
    <p style={{fontSize: `20px`, color: `black`, marginBottom: `10px`}}>Delivery Type and Estimated Delivery</p>
    <p style={{margin: `0px`}}>{shippingCost[0].id}</p>
    <p>{shippingCost[0].name}</p>
    </>}</div><div>
    <div style={{display: `flex`, justifyContent: `center`}}><h1 className="extraM" style={{fontSize: `20px`, marginBottom: `30px`, color: `black`}}>Order Information</h1></div>
    <h1 style={{fontSize: `20px`, marginBottom: `0px`}}>Order Subtotal: <span style={{float: `right`}}>${calcTotal().toFixed(2)}</span></h1>
    <h1 style={{fontSize: `20px`, marginBottom: `0px`, marginLeft: `100px`}}>Tax: <span style={{float: `right`}}>${(Number(tax) * calcTotal()).toFixed(2)}</span></h1>
    {shippingCost && <h1 style={{fontSize: `20px`, marginBottom: `5px`, marginLeft: `54px`}}>Shipping: <span style={{float: `right`}}>${(Number(shippingCost[0].rate))}</span></h1>}
    {shippingCost && <h1 className="lessM" style={{fontSize: `20px`, color: `#e37100`, paddingLeft: `34px`, paddingTop: `5px`, borderTop: `1px solid white`, width: `210px`}}>Order Total: <span style={{float: `right`}}>${(Number(shippingCost[0].rate)+(Number(tax) * calcTotal())+calcTotal()).toFixed(2)}</span></h1>}
    </div>
    </div>
    </div>
    <div style={{display: `flex`, justifyContent: `center`}}><div style={{maxWidth: `600px`}}><h1 style={{fontSize: `20px`, color: `black`, textAlign: `center`, marginBottom: `30px`}}>Please enter your card information below and then click the "PAY NOW" button.</h1>
    <form id="payment-form" onSubmit={handleSubmit}>
      <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
      <button
        disabled={processing || disabled || succeeded}
        id="submit"
        style={{width: `100%`, height: `40px`, fontSize: `20px`, paddingBottom: `2px`, color: `white`, marginTop: `10px`}}
      >
        <span id="button-text">
          {processing ? (
            <div className="spinner" id="spinner"></div>
          ) : (
            "PAY NOW"
          )}
        </span>
      </button>
      {/* Show any error that happens when processing the payment */}
      {error && (
        <div className="card-error" role="alert" style={{color: `red`}}>
          {error}
        </div>
      )}
      {/* Show a success message upon completion */}
      {succeeded && <p className={succeeded ? "result-message" : "result-message hidden"}>
        Payment succeeded, processing your order.
      </p>}
    </form>
    </div>
    </div>
    <Modal
        className="termsModal"
        style={{ border: `none` }}
        show={orderSuccessful}
        onHide={() => {window.location.href = "/"}}
        centered
        scrollable={true}
      >
        <Modal.Header
          style={{
            backgroundImage: `linear-gradient(to bottom, rgba(255,0,0), rgba(252,195,10))`,
            height: `40px`,
            fontWeight: `bold`,
            color: `white`,
            border: `none`,
            
          }}
          closeButton={false}
        >
          <Modal.Title className="modalHeader">
            Order Successful
          </Modal.Title>
          <img
            style={{
              marginTop: `-6px`,
              marginRight: `-10px`,
              cursor: "pointer",
              height: `20px`,
            }}
            onClick={() => {window.location.href = "/"}}
            src={window.location.origin + "/Images/closeTerms.png"}
            alt="close button"
          />
        </Modal.Header>
        <Modal.Body
          style={{
            background: `linear-gradient(rgba(255, 100, 0, 0.2), rgba(252, 195, 10, 0.2))`,
            border: `none`,
            maxHeight: `80vh`,
            overflowY: `auto`,
          }}
          className="loginModalBody"
        >
            <p style={{color: `black`}}>The order has processed successfully.</p>
        </Modal.Body>
      </Modal>
    </div>
  );
}
