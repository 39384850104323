import React from "react";
import Layout from "../Components/layout";
import Checkoutinfo from "../Components/checkoutinfo";
import { Seo } from "../Components/seo";


export default function Checkout() {
  return (
    <Layout>
      <Checkoutinfo />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)